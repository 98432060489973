<template>
	<div class="col-md-10">
		<div class="m-2 my-4">
			<div class="card my-2">
				<div class="card-body">
					<div style="overflow: hidden;">
						<h3 class="float-left">Consumption List</h3>
						<h3 class="float-right">
							<router-link :to="{name : 'ConsumptionPage'}" class="btn btn-primary">Add New</router-link>
						</h3>
					</div>
					<hr>
					<DataTable :data="dataList" :columns="columnss" class="table table-responsive-sm table-striped">
						<thead>
							<tr>
								<th>SL</th>
								<th>Serial</th>
								<th>Weight (KG)</th>
								<th>Action</th>
							</tr>
						</thead>
						<template #column-3="props">
							<Button class="btn btn-info btn-sm mr-2"
							@click="view(props.rowData)"
							><i class="fa fa-eye"></i></Button>
							<Button class="btn btn-primary btn-sm mr-2"
							@click="edit(props.rowData)"
							><i class="fa fa-edit"></i></Button>
							<Button class="btn btn-danger btn-sm"
							@click="remove(props.rowData)"
							><i class="fa fa-trash"></i></Button>
						</template>
					</DataTable>
					<hr>
					<div class="loader" v-if="$store.state.loadding"></div>
				</div>
			</div>
		</div>
	</div>
<!-- View modal -->
<div class="modal fade" id="viewModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static">
	<div class="modal-dialog modal-lg verybigmodal" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel">Consumption Details</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">×</span>
				</button>
			</div>

			<div class="modal-body">
				<table class="table table-bordered table-responsive-md my-2" style="border: 2px solid #ebebe4 !important;">
					<thead>
						<tr>
							<th colspan="2" width="50%">Serial: {{viewData?.serial?.name}}</th>
						</tr>
					</thead>
					<tbody v-if="viewData.content">
						<tr>
							<td colspan="2">
								<table class="table table-responsive-md" style="border: 2px solid #d5d3d7 !important;width:100%" v-for="(style, key) in viewData?.content?.clotings" :key="key">
									<tr>
										<td colspan="2"><strong>Style: {{style.cloting}}</strong></td>
									</tr>
									<tr>
										<td colspan="2" v-if="style">
											<table class="table table-responsive-md" style="border: 2px solid #d5dbf3 !important;" v-for="(size, index) in style.sizes" :key="index">
												<tr>
													<th colspan="5">Size: {{size.size}}</th>
													<th colspan="4">Dozen: {{size.dozen}}</th>
												</tr>
												<tr v-if="size.bodyCheck">
													<th rowspan="2">Body</th>
													<th>Body Length</th>
													<th>Sleeve Length</th>
													<th>Seam Allowance</th>
													<th>1/2 Cheast</th>
													<th>Arm Hole</th>
													<th>GSM</th>
													<th>Waste (%)</th>
													<th>Total</th>
												</tr>
												<tr v-if="size.bodyCheck">
													<td>{{size.body.length}}</td>
													<td>{{size.body.sleeve}}</td>
													<td>{{size.body.seamAllowance}}</td>
													<td>{{size.body.cheast}}</td>
													<td>{{size.body.armHole}}</td>
													<td>{{size.body.gsm}}</td>
													<td>{{size.body.waste}}</td>
													<td>{{size.body.total}}</td>
												</tr>

												<tr v-if="size.collarCheck">
													<th></th>
													<th colspan="2">Length</th>
													<th colspan="2">Width</th>
													<th colspan="2">GSM</th>
													<th>Waste (%)</th>
													<th>Total</th>
												</tr>

												<tr v-if="size.collarCheck">
													<th rowspan="2">Collar</th>
												</tr>
												<tr v-if="size.collarCheck">
													<td colspan="2">{{size.collar.length}}</td>
													<td colspan="2">{{size.collar.width}}</td>
													<td colspan="2">{{size.collar.gsm}}</td>
													<td>{{size.collar.waste}}</td>
													<td>{{size.collar.total}}</td>
												</tr>

												<tr v-if="size.cuffCheck">
													<th rowspan="2">Cuff</th>
												</tr>
												<tr v-if="size.cuffCheck">
													<td colspan="2">{{size.cuff.length}}</td>
													<td colspan="2">{{size.cuff.width}}</td>
													<td colspan="2">{{size.cuff.gsm}}</td>
													<td>{{size.cuff.waste}}</td>
													<td>{{size.cuff.total}}</td>
												</tr>

												<tr v-if="size.pocketCheck">
													<th rowspan="2">Pocket</th>
												</tr>
												<tr v-if="size.pocketCheck">
													<td colspan="2">{{size.pocket.length}}</td>
													<td colspan="2">{{size.pocket.width}}</td>
													<td colspan="2">{{size.pocket.gsm}}</td>
													<td>{{size.pocket.waste}}</td>
													<td>{{size.pocket.total}}</td>
												</tr>

												<tr v-if="size.halfMoonCheck">
													<th rowspan="2">Half Moon</th>
												</tr>
												<tr v-if="size.halfMoonCheck">
													<td colspan="2">{{size.halfmoon.length}}</td>
													<td colspan="2">{{size.halfmoon.width}}</td>
													<td colspan="2">{{size.halfmoon.gsm}}</td>
													<td>{{size.halfmoon.waste}}</td>
													<td>{{size.halfmoon.total}}</td>
												</tr>
												<tr>
													<td colspan="8" class="text-right"><strong>Total (size)</strong></td>
													<td><strong>{{size.total}} KG</strong></td>
												</tr>
											</table>
										</td>
									</tr>
									<tr>
										<td width="50%" class="text-right"><strong>Total (style)</strong></td>
										<td width="50%"><strong>{{style.total}} KG</strong></td>
									</tr>
									<tr>
										<td width="50%" class="text-right"><strong>Total Avg ({{style.avgSizes}})</strong></td>
										<td width="50%"><strong>{{style.avgTotal}} KG</strong></td>
									</tr>
								</table>
							</td>
						</tr>
					</tbody>
					<tfoot>
						<tr>
							<td width="80%" class="text-right"><strong>Grand Total</strong></td>
							<td width="20%"><strong>{{viewData?.content?.total}} KG</strong></td>
						</tr>
					</tfoot>
				</table>
			</div>
		</div>
	</div>
</div>
</template>
<script>
import { mapState } from 'vuex';
import DataTable from 'datatables.net-vue3';
import DataTablesCore from 'datatables.net';
import  'datatables.net-bs4';
import { onMounted } from 'vue';
import $ from 'jquery';
import 'bootstrap';
DataTable.use(DataTablesCore);
let viewModal = null;
export default {
	setup(){
		onMounted(()=>{
			viewModal = $("#viewModal");
		})
	},
	data() {
		return {
			viewData : "",
			columnss : [
				{data : null, render : function(data, type, row, meta){ return `${meta.row + 1}`}},
				{data : "serial.name"},
				{data : "content.total"},
			]
		}
	},
	computed : {
		...mapState('consumption', {
			dataList : state => state.dataList
		})
	},
	methods: {
		view(data){
			viewModal.modal("show");
			this.viewData = data;
		},
		edit(){
			this.$router.push({name: 'ConsumptionPage'});
		},
		update() {
			this.$store.dispatch("shipment/update", this.updateData);
			this.updateData.avatar 	= "";
		},
		remove(data) {
			if(confirm("All payment, style, color, size delete?")){
				this.$store.dispatch("consumption/remove", data);
			}
		},
	},
	mounted(){
		this.$store.dispatch("consumption/getAllData");
	},
	components : {
		DataTable
	},
	created(){
		document.title = "Consumption List"
	}
};
</script>
<style lang="css" scoped>
.verybigmodal {
	max-width: 60% !important;
}
th {
	vertical-align: middle !important;
}

@media(max-width : 1024px){
	.verybigmodal {
		max-width: 80% !important;
	}
}
@media(max-width : 768px){
	.verybigmodal {
		max-width: 100% !important;
	}
}
@media(max-width : 425px){
	.verybigmodal {
		max-width: 100% !important;
	}
}
</style>