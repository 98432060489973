import { createRouter, createWebHistory } from 'vue-router';

//Login & registration pages
import login from './pages/admin/LoginPage.vue';
import register from './pages/admin/RegisterPage.vue';


//Admin Pages
import LayoutPage from './pages/admin/inc/LayoutPage.vue';
import DashboardPage from './pages/admin/DashboardPage.vue';
import UserlistPage from './pages/admin/UserlistPage.vue';
import AdminList from './pages/admin/AdminList.vue';
import AdminProfile from './pages/admin/AdminProfile.vue';
import SetupPage from './pages/admin/SetupPage.vue';
import ShippingPage from './pages/admin/ShippingPage.vue';
import ColorPage from './pages/admin/ColorPage.vue';
import SizePage from './pages/admin/SizePage.vue';
import PackagePage from './pages/admin/PackagePage.vue';
import SerialPage from './pages/admin/SerialPage.vue';
import ClothingPage from './pages/admin/ClothingPage.vue';
import ShipmentPage from './pages/admin/ShipmentPage.vue';
import ShipmentList from './pages/admin/ShipmentList.vue';
import ShipmentDetails from './pages/admin/ShipmentDetails.vue';
import PaymentPage from './pages/admin/PaymentPage.vue';
import ShippingReport from './pages/admin/ShippingReport.vue';
import ShipmentEdit from './pages/admin/ShipmentEdit.vue';
import ForgotPassword from './pages/admin/ForgotPassword.vue';
import PassConfirm from './pages/admin/PassConfirm.vue';

import ConsumptionPage from './pages/admin/consumption/ConsumptionPage.vue';
import ConsumptionList from './pages/admin/consumption/ConsumptionList.vue';



const routes = [
	{path: "/login", name : 'login', component : login, meta : { auth : false}},
	{path: "/register", name : 'register', component : register, meta : { auth : false}},
	{path: "/forgot", name : 'ForgotPassword', component : ForgotPassword, meta : { auth : false}},
	{path: "/check", name : 'PassConfirm', component : PassConfirm, meta : { auth : false}},
	{
		path : "/",
		component : LayoutPage,
		redirect : '/admin',
		children : [
			{path: "/admin", name : 'dashboard', component : DashboardPage, meta : { auth : true, roles : ['admin']}},
			{path: "users", name : 'users', component : UserlistPage, meta : { auth : true , roles : ['admin']}},
			{path: "adminlist", name : 'adminlist', component : AdminList, meta : { auth : true ,roles : ['admin']}},
			{path: "profile", name : 'AdminProfile', component : AdminProfile, meta : { auth : true ,roles : ['admin']}},
			{path: "setup", name : 'SetupPage', component : SetupPage, meta : { auth : true ,roles : ['admin']}},
			{path: "shippings", name : 'ShippingPage', component : ShippingPage, meta : { auth : true ,roles : ['admin']}},
			{path: "colors", name : 'ColorPage', component : ColorPage, meta : { auth : true ,roles : ['admin']}},
			{path: "sizes", name : 'SizePage', component : SizePage, meta : { auth : true ,roles : ['admin']}},
			{path: "packages", name : 'PackagePage', component : PackagePage, meta : { auth : true ,roles : ['admin']}},
			{path: "serials", name : 'SerialPage', component : SerialPage, meta : { auth : true ,roles : ['admin']}},
			{path: "clothings", name : 'ClothingPage', component : ClothingPage, meta : { auth : true ,roles : ['admin']}},
			{path: "shipments", name : 'ShipmentPage', component : ShipmentPage, meta : { auth : true ,roles : ['admin']}},
			{path: "shipmentList", name : 'ShipmentList', component : ShipmentList, meta : { auth : true ,roles : ['admin']}},
			{path: "details/:slug", name : 'ShipmentDetails', component : ShipmentDetails, meta : { auth : true ,roles : ['admin']}},
			{path: "payment", name : 'PaymentPage', component : PaymentPage, meta : { auth : true ,roles : ['admin']}},
			{path: "shipping-report", name : 'ShippingReport', component : ShippingReport, meta : { auth : true ,roles : ['admin']}},
			{path: "edit", name : 'ShipmentEdit', component : ShipmentEdit, meta : { auth : true ,roles : ['admin']}},
			{path: "consumptions", name : 'ConsumptionPage', component : ConsumptionPage, meta : { auth : true ,roles : ['admin']}},
			{path: "consumptionlist", name : 'ConsumptionList', component : ConsumptionList, meta : { auth : true ,roles : ['admin']}},
		]
	}
	
];

const router = createRouter({
	routes,
	history : createWebHistory(),
	scrollBehavior(){
		return {
			top : 0
		}
	}
});

router.beforeEach((to, form, next) => {

let token 		= localStorage.getItem('accessToken');
let user_type 	= [localStorage.getItem('user_type')];

	if(to.meta.auth) {
		if (!token) {
			next('/login')
		} else if (!user_type.some(role => to.meta.roles.includes(role))) {
			if(user_type == "admin"){
				next({name : 'ShipmentList'});
			}
		} else {
			next()
		}
	}else if('auth' in to.meta && !to.meta.auth && token) {
		if(user_type == "admin"){
			next({name : 'ShipmentList'});
		}
	}else {
		next()
	}

});


export default router;