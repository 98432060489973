<template>
  <div class="col-md-10">
    <div class="m-2 my-4">
      <div class="card my-2">
        <div class="card-body">
          <div style="overflow: hidden;">
            <h3 class="float-left">Fabrics Consumption</h3>
          </div>
          <div class="row">
            <div class="col-md-12">
              <form enctype="multipart/form-data" @submit.prevent="insert">
                <p class="text-center text-danger" v-if="$store?.state?.errors?.network_error">{{$store?.state?.errors?.network_error}}</p>
                <div class="row">
                  <div class="col-md-12 my-2">
                    <hr>
                    <select class="form-control" @change="getStoreData(insertData)" v-model="insertData.serial_id" required>
                      <option value="">Select Serial</option>
                      <option :value="serial.id" v-for="serial in serials" :key="serial.id">{{serial.name}}</option>
                    </select>
                  </div>
                  <div class="col-md-12">
                    <div class="row mx-3">
                      <div class="col-md-12 my-2" v-for="(cloting, key) in insertData.clotings" :key="key">
                        <hr>
                        <div class="row">
                          <div class="col-md-11">
                            <select class="form-control" v-model="cloting.clothing_id" @change="duplicateStyleCheck(insertData.clotings, cloting)" required>
                              <option value="">Select Style</option>
                              <option :value="cloth.id" v-for="cloth in cloths" :key="cloth.id">{{cloth.name}}</option>
                            </select>
                          </div>

                          <div class="col-md-1">
                            <button v-if="key != 0" class="btn btn-danger" type="button" @click="addMoreRemove(insertData.clotings, key)"><i class="fa fa-trash"></i></button>
                          </div>

                          <div class="col-md-12 my-2">
                            <div class="row mx-3 my-2">
                              <div class="col-md-9">
                                <b>Item Information</b>
                                <hr>
                              </div>
                              <div class="col-md-2">
                                <b></b>
                              </div>
                              <div class="col-md-1"></div>
                            </div>

                            <div class="row mx-3" v-for="(size, id) in cloting.sizes" :key="id">
                              <div class="col-md-9 my-2">
                                <div class="row">
                                  <div class="col-md-6">
                                    <select class="form-control" @change="duplicateCheck(cloting.sizes, size)" v-model="size.size_id" required>
                                      <option value="">Size</option>
                                      <option :value="size.id" v-for="size in sizes" :key="size.id">{{size.name}}</option>
                                    </select>
                                  </div>
                                  <div class="col-md-6">
                                    <input type="number" step="0.01" v-model="size.dozen" min="1" class="form-control" placeholder="Dozen" required>
                                  </div>
                                  <!-- Body Part -->
                                  <div class="col-md-12 mt-3 mx-5">
                                    <div class="row">
                                      <div class="col-md-10">
                                        <div class="form-check form-check-inline">
                                          <input class="form-check-input" @click="valueClear(size, 'body')" v-model="size.bodyCheck" type="checkbox" :id="key+'_'+id+'body'">
                                          <label class="form-check-label" :for="key+'_'+id+'body'"><b>Body Part</b></label>
                                        </div>
                                        <br>
                                      </div>
                                      <div class="col-md-2">
                                      </div>
                                    </div>
                                    <div class="row" v-if="size.bodyCheck">
                                      <div class="col-md-10">
                                        <div class="row">

                                          <div class="col-md-6 my-2">
                                            <input type="number" step="0.01" min="0" v-model.number="size.body.length" class="form-control" placeholder="Body Length (cm)" required>
                                          </div>

                                          <div class="col-md-3 my-2">
                                            <input type="number" step="0.01" min="0" v-model.number="size.body.sleeve" class="form-control" placeholder="Sleeve Length (cm)" required>
                                          </div>
                                          <div class="col-md-3 my-2">
                                            <input type="number" step="0.01" min="0" v-model.number="size.body.seamAllowance" class="form-control" placeholder="Seam Allowance (cm)" required>
                                          </div>
                                          <div class="col-md-3 my-2">
                                            <input type="number" step="0.01" min="0" v-model.number="size.body.cheast" class="form-control" placeholder="1/2 Cheast (cm)" required>
                                          </div>
                                          <div class="col-md-4 my-2">
                                            <input type="number" step="0.01" min="0" v-model.number="size.body.armHole" class="form-control" placeholder="ArmHole (cm)" required>
                                          </div>
                                          <div class="col-md-4 my-2">
                                            <input type="number" step="0.01" min="0" v-model.number="size.body.gsm" class="form-control" placeholder="GSM" required>
                                          </div>
                                          <div class="col-md-4 my-2">
                                            <input type="number" step="0.01" min="0" v-model.number="size.body.waste" class="form-control" placeholder="Waste (%)" required>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-2 my-2">
                                        <span v-if="size.body">{{size.body.total}}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- Collar Part -->
                                  <div class="col-md-12 mt-3 mx-5">
                                    <div class="row">
                                      <div class="col-md-12">
                                        <div class="form-check form-check-inline">
                                          <input class="form-check-input" @click="valueClear(size, 'collar')" v-model="size.collarCheck" type="checkbox" :id="key+'_'+id+'color'">
                                          <label class="form-check-label" :for="key+'_'+id+'color'"><b>Collar</b></label>
                                        </div>
                                        <br>
                                      </div>
                                    </div>
                                    <div class="row" v-if="size.collarCheck">
                                      <div class="col-md-10">
                                        <div class="row">
                                          <div class="col-md-3 my-2">
                                            <input type="number" step="0.01" min="0" v-model.number="size.collar.length" class="form-control" placeholder="Collar Length (cm)" required>
                                          </div>
                                          <div class="col-md-3 my-2">
                                            <input type="number" step="0.01" min="0" v-model.number="size.collar.width" class="form-control" placeholder="Collar Width (cm)" required>
                                          </div>
                                          <div class="col-md-3 my-2">
                                            <input type="number" step="0.01" min="0" v-model.number="size.collar.gsm" class="form-control" placeholder="GSM" required>
                                          </div>
                                          <div class="col-md-3 my-2">
                                            <input type="number" step="0.01" min="0" v-model.number="size.collar.waste" class="form-control" placeholder="Waste (%)" required>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-2 my-2">
                                        <span v-if="size.collar">{{size.collar.total}}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- Cuff Part -->
                                  <div class="col-md-12 mt-3 mx-5">
                                    <div class="row">
                                      <div class="col-md-12">
                                        <div class="form-check form-check-inline">
                                          <input class="form-check-input" @click="valueClear(size, 'cuff')" v-model="size.cuffCheck" type="checkbox" :id="key+'_'+id+'cuff'">
                                          <label class="form-check-label" :for="key+'_'+id+'cuff'"><b>Cuff</b></label>
                                        </div>
                                        <br>
                                      </div>
                                    </div>
                                    <div class="row" v-if="size.cuffCheck">
                                      <div class="col-md-10">
                                        <div class="row">
                                          <div class="col-md-3 my-2">
                                            <input type="number" step="0.01" min="0" v-model.number="size.cuff.length" class="form-control" placeholder="Cuff Length (cm)" required>
                                          </div>
                                          <div class="col-md-3 my-2">
                                            <input type="number" step="0.01" min="0" v-model.number="size.cuff.width" class="form-control" placeholder="Cuff Width (cm)" required>
                                          </div>
                                          <div class="col-md-3 my-2">
                                            <input type="number" step="0.01" min="0" v-model.number="size.cuff.gsm" class="form-control" placeholder="GSM" required>
                                          </div>
                                          <div class="col-md-3 my-2">
                                            <input type="number" step="0.01" min="0" v-model.number="size.cuff.waste" class="form-control" placeholder="Waste (%)" required>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-2 my-2">
                                        <span v-if="size.cuff">{{size.cuff.total}}</span>
                                      </div>
                                    </div>
                                  </div> 
                                  <!-- Pocket Part -->
                                  <div class="col-md-12 mt-3 mx-5">
                                    <div class="row">
                                      <div class="col-md-12">
                                        <div class="form-check form-check-inline">
                                          <input class="form-check-input" @click="valueClear(size, 'pocket')" v-model="size.pocketCheck" type="checkbox" :id="key+'_'+id+'Pocket'" value="option1">
                                          <label class="form-check-label" :for="key+'_'+id+'Pocket'"><b>Pocket</b></label>
                                        </div>
                                        <br>
                                      </div>
                                    </div>
                                    <div class="row" v-if="size.pocketCheck">
                                      <div class="col-md-10">
                                        <div class="row">
                                          <div class="col-md-3 my-2">
                                            <input type="number" step="0.01" min="0" v-model.number="size.pocket.length" class="form-control" placeholder="Pocket Length (cm)" required>
                                          </div>
                                          <div class="col-md-3 my-2">
                                            <input type="number" step="0.01" min="0" v-model.number="size.pocket.width" class="form-control" placeholder="Pocket Width (cm)" required>
                                          </div>
                                          <div class="col-md-3 my-2">
                                            <input type="number" step="0.01" min="0" v-model.number="size.pocket.gsm" class="form-control" placeholder="GSM" required>
                                          </div>
                                          <div class="col-md-3 my-2">
                                            <input type="number" step="0.01" min="0" v-model.number="size.pocket.waste" class="form-control" placeholder="Waste (%)" required>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-2 my-2">
                                        <span v-if="size.pocket">{{size.pocket.total}}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- Half Moon -->
                                  <div class="col-md-12 mt-3 mx-5">
                                    <div class="row">
                                      <div class="col-md-10">
                                        <div class="form-check form-check-inline">
                                          <input class="form-check-input" @click="valueClear(size, 'halfmoon')" v-model="size.halfMoonCheck" type="checkbox" :id="key+'_'+id+'halfMoonCheck'">
                                          <label class="form-check-label" :for="key+'_'+id+'halfMoonCheck'"><b>Half Moon</b></label>
                                        </div>
                                        <br>
                                      </div>
                                    </div>
                                    <div class="row" v-if="size.halfMoonCheck">
                                      <div class="col-md-10">
                                        <div class="row">
                                          <div class="col-md-3 my-2">
                                            <input type="number" step="0.01" min="0" v-model.number="size.halfmoon.length" class="form-control" placeholder="Half Moon Length (cm)" required>
                                          </div>
                                          <div class="col-md-3 my-2">
                                            <input type="number" step="0.01" min="0" v-model.number="size.halfmoon.width" class="form-control" placeholder="Half Moon Width (cm)" required>
                                          </div>
                                          <div class="col-md-3 my-2">
                                            <input type="number" step="0.01" min="0" v-model.number="size.halfmoon.gsm" class="form-control" placeholder="GSM" required>
                                          </div>
                                          <div class="col-md-3 my-2">
                                            <input type="number" step="0.01" min="0" v-model.number="size.halfmoon.waste" class="form-control" placeholder="Waste (%)" required>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-2 my-2">
                                        <span v-if="size.halfmoon">{{size.halfmoon.total}}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- Hood -->
                                  <div class="col-md-12 mt-3 mx-5">
                                    <div class="row">
                                      <div class="col-md-10">
                                        <div class="form-check form-check-inline">
                                          <input class="form-check-input" @click="valueClear(size, 'hood')" v-model="size.hoodCheck" type="checkbox" :id="key+'_'+id+'hoodCheck'">
                                          <label class="form-check-label" :for="key+'_'+id+'hoodCheck'"><b>Hood</b></label>
                                        </div>
                                        <br>
                                      </div>
                                    </div>
                                    <div class="row" v-if="size.hoodCheck">
                                      <div class="col-md-10">
                                        <div class="row">
                                          <div class="col-md-3 my-2">
                                            <input type="number" step="0.01" min="0" v-model.number="size.hood.height" class="form-control" placeholder="Hood Height(cm)" required>
                                          </div>
                                          <div class="col-md-3 my-2">
                                            <input type="number" step="0.01" min="0" v-model.number="size.hood.width" class="form-control" placeholder="Hood Width (cm)" required>
                                          </div>
                                          <div class="col-md-3 my-2">
                                            <input type="number" step="0.01" min="0" v-model.number="size.hood.gsm" class="form-control" placeholder="GSM" required>
                                          </div>
                                          <div class="col-md-3 my-2">
                                            <input type="number" step="0.01" min="0" v-model.number="size.hood.waste" class="form-control" placeholder="Waste (%)" required>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-2 my-2">
                                        <span v-if="size.hood">{{size.hood.total}}</span>
                                      </div>
                                    </div>
                                    <div class="row">
                                      <div class="col-md-10">
                                        <p class="text-right my-3"><b>Total (Size):</b></p>
                                      </div>
                                      <div class="col-md-2 my-3">
                                        <span><b>{{size.total}} KG</b></span>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="form-check form-check-inline mt-2">
                                  <input class="form-check-input" v-model="size.avgCheck" type="checkbox" :id="id+'_'+key+'_avg'">
                                  <label class="form-check-label" :for="id+'_'+key+'_avg'"><b>Avg</b></label>
                                </div>
                              </div>
                              <div class="col-md-1">
                                <button v-if="id != 0" class="btn btn-danger" type="button" @click="moreSizeRemove(cloting.sizes, id)">x</button>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-10 mx-4">
                            <div class="row">
                              <div class="col-md-10 text-right">
                                <strong>Total (Style)</strong>
                              </div>
                              <div class="col-md-2">
                                <strong> {{cloting.total}} KG</strong>
                              </div>
                            </div>
                            <div class="row" v-if="cloting.avgTotal">
                              <div class="col-md-7 text-right">
                                <strong>Total Avg ( {{cloting.avgSizes}} )</strong>
                              </div>
                              <div class="col-md-5">
                                <strong> {{cloting.avgTotal}} KG</strong>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-10 mx-4">
                            <div class="text-left">
                              <button class="btn btn-info btn-sm" type="button" @click="moreSize(cloting)">More Size +</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-10">
                            <p class="text-right"><b>Grand Total</b></p>
                            <hr>
                          </div>
                          <div class="col-md-2">
                            <b>{{insertData.total}} KG</b>
                            <hr>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="text-left">
                          <button class="btn btn-primary btn-sm my-2" type="button" @click="moreStyle(insertData)">More Style +</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="text-right">
                  <TheButton :disabled="$store?.state?.loadding" :text="'Save Change'" :loading="$store.state.loadding"></TheButton>
                </div>
              </form>
              <hr>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import TheButton from "../../../helper/TheButton.vue";
import myAxios from "../../../service/axios.js";
export default {
  data() {
    return {
      serials    : [],
      cloths     : [],
      sizes      : [],
      insertData : {
        serial_id   : '',
        total       : 0,
        clotings : [{
          clothing_id : "",
          cloting : "",
          total : "",
          avgTotal : "",
          avgSizes : "",
          sizes : [{ "size_id" : "", "total" : 0, "avgCheck" : false, "size" : "", "dozen" : 1, "bodyCheck" : true, "collarCheck" : false, "cuffCheck" : false, "pocketCheck" : false, "halfMoonCheck" : false, "hoodCheck" : false, "body" : { "total" : "", "length": "", "armHole": "", "sleeve": "", "gsm": "", "seamAllowance": "", "waste": "", "cheast": "" }, "collar" : { "total" : "", "length": "", "width": "", "gsm": "", "waste": "" }, "cuff" : { "total" : "", "length": "", "width": "", "gsm": "", "waste": "" }, "pocket" : { "total" : "", "length": "", "width": "", "gsm": "", "waste": "" }, "halfmoon" : { "total" : "", "length": "", "width": "", "gsm": "", "waste": ""}, "hood" : { "total" : "", "height": "", "width": "", "gsm": "", "waste": ""}}
          ]
        }
      ]
    }
  }
},
computed : {
  ...mapState('shipping', {
    dataList : state => state.dataList
  }),
},
watch : {
  'insertData' : {
    handler(newValue){
      let gradTotal = 0;
      newValue.clotings.forEach((parent) => {
        let sizeTotal = 0;
/* For Avg calculation */
        let avgCount = 0;
        let avgTotal = 0;
        let sizes = [];
        parent.sizes.forEach((child) => {
          if(child.avgCheck){
            sizes.push(child.size);
            avgCount += 1;
            avgTotal += child.total;
          }
/* For Body  calculation */
          let calPartOne      = child.body.length + child.body.sleeve + child.body.seamAllowance;
          let calPartTwo      = child.body.cheast + child.body.armHole + child.body.seamAllowance;
          let withoutwast     = (calPartOne * calPartTwo * (2 * child.body.gsm * (12 * child.dozen))) / 10000000;
          let result          = withoutwast+(withoutwast * ( child.body.waste / 100));
          child.body.total    = Math.round( result * Math.pow(10, 5)) / Math.pow(10, 5);
/* For collar calculation */
          const collarTotal   = (child.collar.length * child.collar.width * 12 * child.dozen * child.collar.gsm)/10000000;
          const collarWaste   = (collarTotal * child.collar.waste)/100;
          const collarresult  = collarTotal + collarWaste;
          child.collar.total  = Math.round( collarresult * Math.pow(10, 5)) / Math.pow(10, 5);
/* For cuff calculation */
          const cuffTotal   = (child.cuff.length * child.cuff.width * 12 * child.dozen * child.cuff.gsm)/10000000;
          const cuffWaste  = (cuffTotal * child.cuff.waste)/100;
          const cuffresult  = cuffTotal + cuffWaste;
          child.cuff.total  = Math.round( cuffresult * Math.pow(10, 5)) / Math.pow(10, 5);
/* For pocket calculation */
          const pocketTotal   = (child.pocket.length * child.pocket.width * 12 * child.dozen * child.pocket.gsm)/10000000;
          const pocketWaste  = (pocketTotal * child.pocket.waste)/100;
          const pocketresult  = pocketTotal + pocketWaste;
          child.pocket.total  = Math.round( pocketresult * Math.pow(10, 5)) / Math.pow(10, 5);
/* For halfmoon calculation */
          const halfmoonTotal   = (child.halfmoon.length * child.halfmoon.width * 12 * child.dozen * child.halfmoon.gsm)/10000000;
          const halfmoonWaste  = (halfmoonTotal * child.halfmoon.waste)/100;
          const halfmoonresult  = halfmoonTotal + halfmoonWaste;
          child.halfmoon.total  = Math.round( halfmoonresult * Math.pow(10, 5)) / Math.pow(10, 5);
/* For hood calculation */
          const hoodTotal   = (child.hood.height * child.hood.width * 2 * child.hood.gsm)/10000000;
          const hoodWaste  = (hoodTotal * child.hood.waste)/100;
          const hoodresult  = (hoodTotal + hoodWaste) * (12*child.dozen);
          child.hood.total  = Math.round( hoodresult * Math.pow(10, 5)) / Math.pow(10, 5);
/* For Total calculation */
          var subTotal = child.halfmoon.total + child.pocket.total + child.cuff.total + child.collar.total + child.body.total + child.hood.total;
          child.total = Math.round( subTotal * Math.pow(10, 5)) / Math.pow(10, 5);
          sizeTotal += child.total;
        });
/* For Grad Total Avg calculation */
        let gradTotalAvg  = (avgTotal/avgCount);
        parent.avgTotal   = Math.round( gradTotalAvg * Math.pow(10, 5)) / Math.pow(10, 5);
        parent.avgSizes   = sizes.join(", ");
/* For Total calculation */
        parent.total = Math.round(sizeTotal * Math.pow(10, 5)) / Math.pow(10, 5);
        gradTotal   += Math.round( parent.total * Math.pow(10, 5)) / Math.pow(10, 5);
      })
      newValue.total = Math.round(gradTotal * Math.pow(10, 5)) / Math.pow(10, 5);
    },
    deep : true,
    immediate : false
  }
},
methods: {
  duplicateCheck(getSizes, size){
    if(size.size_id != ""){
      let result = getSizes.filter(data => data.size_id == size.size_id).length;
      if(result > 1){
        alert("This size already exists. Please choose a different size.");
        size.size_id = "";
      }else{
        let sizeFound = this.sizes.find(data => data.id == size.size_id);
        size.size = sizeFound.name;
      }
    }
  },  
  duplicateStyleCheck(getClotings, cloting){
    if(cloting.clothing_id != ""){
      let result = getClotings.filter(data => data.clothing_id == cloting.clothing_id).length;
      if(result > 1){
        alert("This style already exists. Please choose a different size.");
        cloting.clothing_id = "";
      }else{
        let clothFound = this.cloths.find(data => data.id == cloting.clothing_id);
        cloting.cloting = clothFound.name;
      }
    }
  },
  async getStoreData(data){
    if(data.serial_id && data.serial_id != ""){
      let store = {
        serial_id   : data?.serial_id
      }
      let res = await this.$store.dispatch("consumption/getData", store);
      if(res){
        this.insertData = res.content;
      }else{
        this.insertData = {"serial_id": "", "total": 0, "clotings": [ { "clothing_id": "", "cloting": "", "total": "", "avgTotal": "", "avgSizes": "", "sizes": [ { "size_id": "", "total": 0, "avgCheck": false, "size": "", "dozen": 1, "bodyCheck": true, "collarCheck": false, "cuffCheck": false, "pocketCheck": false, "halfMoonCheck": false, "hoodCheck": false, "body": { "total": "", "length": "", "armHole": "", "sleeve": "", "gsm": "", "seamAllowance": "", "waste": "", "cheast": "" }, "collar": { "total": "", "length": "", "width": "", "gsm": "", "waste": "" }, "cuff": { "total": "", "length": "", "width": "", "gsm": "", "waste": "" }, "pocket": { "total": "", "length": "", "width": "", "gsm": "", "waste": "" }, "halfmoon": { "total": "", "length": "", "width": "", "gsm": "", "waste": "" }, "hood": { "total": "", "height": "", "width": "", "gsm": "", "waste": ""}}]}]}
        this.insertData.serial_id   = data.serial_id;
      }
    }
  },
  valueClear(data, identifier){
    if(data.bodyCheck && identifier == "body"){
      data.body = { "total": "", "length": "", "armHole": "", "sleeve": "", "gsm": "", "seamAllowance": "", "waste": "", "cheast": ""};
    }
    if(data.collarCheck && identifier == "collar"){
      data.collar =  { "total": "", "length": "", "width": "", "gsm": "", "waste": "" };
    }
    if(data.cuffCheck && identifier == "cuff"){
      data.cuff =  { "total": "", "length": "", "width": "", "gsm": "", "waste": "" };
    }
    if(data.pocketCheck && identifier == "pocket"){
      data.pocket =  { "total": "", "length": "", "width": "", "gsm": "", "waste": "" };
    }
    if(data.halfMoonCheck && identifier == "halfmoon"){
      data.halfmoon =  { "total": "", "length": "", "width": "", "gsm": "", "waste": "" };
    }
    if(data.hoodCheck && identifier == "hood"){
      data.hood =  { "total": "", "height": "", "width": "", "gsm": "", "waste": "" };
    }
  },
  async serialList(){
    try{
      await myAxios
      .get("admin/getSerial", {
        headers : {
          "Authorization" : "Bearer "+localStorage.getItem('accessToken')
        }
      })
      .then((response) => {
        this.serials = response.data.data
      })
    }catch(err){
      console.log(err)
    }
  },
  async clothinglist(){
    try{
      await myAxios
      .get("admin/getClothing", {
        headers : {
          "Authorization" : "Bearer "+localStorage.getItem('accessToken')
        }
      })
      .then((response) => {
        this.cloths = response.data.data
      })
    }catch(err){
      console.log(err)
    }
  },      
  async sizelist(){
    try{
      await myAxios
      .get("admin/getSize", {
        headers : {
          "Authorization" : "Bearer "+localStorage.getItem('accessToken')
        }
      })
      .then((response) => {
        this.sizes = response.data.data
      })
    }catch(err){
      console.log(err)
    }
  },
  moreStyle(data){
    data.clotings.push({ "clothing_id": "", "cloting": "", "total": "", "avgTotal": "", "avgSizes": "", "sizes": [ { "size_id": "", "total": 0, "avgCheck": false, "size": "", "dozen": 1, "bodyCheck": true, "collarCheck": false, "cuffCheck": false, "pocketCheck": false, "halfMoonCheck": false, "hoodCheck": false, "body": { "total": "", "length": "", "armHole": "", "sleeve": "", "gsm": "", "seamAllowance": "", "waste": "", "cheast": "" }, "collar": { "total": "", "length": "", "width": "", "gsm": "", "waste": "" }, "cuff": { "total": "", "length": "", "width": "", "gsm": "", "waste": "" }, "pocket": { "total": "", "length": "", "width": "", "gsm": "", "waste": "" }, "halfmoon": { "total": "", "length": "", "width": "", "gsm": "", "waste": "" }, "hood": { "total": "", "height": "", "width": "", "gsm": "", "waste": "" }}]});
  },    
  moreSize(item){
    item.sizes.push({ "size_id": "", "total": 0, "avgCheck": false, "size": "", "dozen": 1, "bodyCheck": true, "collarCheck": false, "cuffCheck": false, "pocketCheck": false, "halfMoonCheck": false, "hoodCheck": false, "body": { "total": "", "length": "", "armHole": "", "sleeve": "", "gsm": "", "seamAllowance": "", "waste": "", "cheast": "" }, "collar": { "total": "", "length": "", "width": "", "gsm": "", "waste": "" }, "cuff": { "total": "", "length": "", "width": "", "gsm": "", "waste": "" }, "pocket": { "total": "", "length": "", "width": "", "gsm": "", "waste": "" }, "halfmoon": { "total": "", "length": "", "width": "", "gsm": "", "waste": "" }, "hood": { "total": "", "height": "", "width": "", "gsm": "", "waste": "" }});
  },    
  moreSizeRemove(item, index){
    item.splice(index, 1);
  },
  async insert(){
    let res = await this.$store.dispatch("consumption/insert", this.insertData);
    if(res){
      this.toastr.success('Consumption stored successfully.');
      this.insertData = { "serial_id": "", "total": 0, "clotings": [ { "clothing_id": "", "cloting": "", "total": 0, "avgTotal": null, "avgSizes": "", "sizes": [ { "size_id": "", "total": 0, "avgCheck": false, "size": "", "dozen": 1, "bodyCheck": false, "collarCheck": false, "cuffCheck": false, "pocketCheck": false, "halfMoonCheck": false, "hoodCheck": false, "body": { "total": 0, "length": "", "armHole": "", "sleeve": "", "gsm": "", "seamAllowance": "", "waste": "", "cheast": "" }, "collar": { "total": 0, "length": "", "width": "", "gsm": "", "waste": "" }, "cuff": { "total": 0, "length": "", "width": "", "gsm": "", "waste": "" }, "pocket": { "total": 0, "length": "", "width": "", "gsm": "", "waste": "" }, "halfmoon": { "total": 0, "length": "", "width": "", "gsm": "", "waste": "" }, "hood": { "total": 0, "height": "", "width": "", "gsm": "", "waste": "" }}]}]}
    }
  },
  addMoreRemove(item, index){
    item.splice(index, 1);
  },
},
mounted(){
  this.serialList();
  this.clothinglist();
  this.sizelist();
},
components : {
  TheButton
},
created(){
  document.title = "Consumption"
}
};
</script>